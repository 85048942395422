import React, { Component } from 'react';
import Logo from './logo/logo.svg';
import './App.css';
import Buzz from './Buzz.js';
import TuffStuff from './TuffStuff';

class App extends Component {
    state = {
        svg: '',
    };
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Buzz/>
                    <Logo />
                    {/* <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
                    <TuffStuff />
                </header>
            </div>
        );
    }
}

export default App;
