import React from 'react';

const techs = [
    {
        name: 'webpack',
        logo: 'https://raw.githubusercontent.com/webpack/media/master/logo/logo-on-dark-bg.png',
    },
    {
        name: 'NodeJS',
        logo: 'https://nodejs.org/static/images/logos/nodejs-new-pantone-white.png',
    },
    {
        name: 'ReactJS',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg',
    },
    {
        name: 'Redux',
        logo: 'https://raw.githubusercontent.com/reduxjs/redux/master/logo/logo-title-light.png',
    },
];

function calcTop() {
    let y = 50 + Math.floor(Math.random() * Math.floor(window.innerHeight - 100));
    y = y - y % 50;
    return y;
}

class Buzz extends React.Component {
    handleClick = () => {
        alert();
    };
    render = () => {
        return techs.map(tech => (
            <img
                style={{
                    position: 'absolute',
                    left: -500,
                    top: calcTop(),
                    maxHeight: 53,
                    '-webkit-filter': 'grayscale(100%)',
                    filter: 'grayscale(100%)',
                    opacity: 0.3,
                    animation: `${20 + Math.floor(Math.random() * 40)}s flyLeft linear`,
                }}
                src={tech.logo}
                alt={tech.name}
            />
        ));
    };

    componentDidMount = () => {
        window.addEventListener('animationend', event => {
            if (event.animationName === 'flyLeft') {
                window.requestAnimationFrame(() => {
                    event.target.style.animation = null;
                    window.requestAnimationFrame(() => {
                        event.target.style.top = calcTop();
                        event.target.style.animation = `${20 + Math.floor(Math.random() * 40)}s flyLeft linear`;
                    });
                });
            }
        });

        const styleElement = document.createElement('style');
        styleElement.innerHTML = `@keyframes flyLeft {
            0% {
                transform: translateX(calc(100vw + 500px));
            }
            100% {
                transform: translateX(0);
            }
        }`;
        document.body.appendChild(styleElement);
    };
}

export default Buzz;
