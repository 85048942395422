import stringToBinary from './stringToBinary';
import React from 'react';
import styled from 'styled-components';

const BinWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 325px;
    font-size: 1.3rem;
    position: absolute;
    color: #c8b7b702;
    font-family: monospace;
`;

const Letter = styled.div``;

const Bit = styled.div`
    display: inline-block;
`;

const text = 'generic constructions';

class TuffStuff extends React.Component {
    bits = 0;

    render = () => {
        return (
            <BinWrapper>
                {stringToBinary(text)
                    .split(' ')
                    .map((x, i) => (
                        <Letter key={`letter_${i}`} className={`letter_${text[i]}`}>
                            {Array.from(x).map((bit, j) => (
                                <Bit key={`bit_${this.bits}`} className={`bit_${this.bits++}`}>
                                    {bit}
                                </Bit>
                            ))}
                        </Letter>
                    ))}
            </BinWrapper>
        );
    };

    currentRow = 0;
    currentColumn = 0;
    currentBit = 0;

    animateRow = row => () => {
        const selector = `.bit_${Math.floor(Math.random() * Math.floor(this.bits))}`;
        const e = document.querySelector(selector);
        if (e) {
            e.style.animation = `5s fadeOut ease-out`;
        }

        if (this.currentRow === 7) {
            this.currentRow = 0;
        } else {
            this.currentRow++;
        }

        setTimeout(() => {
            this.animationFrame = window.requestAnimationFrame(this.animateRow(this.currentRow));
        }, 200);
    };

    animationFrame;

    startAnimating = () => {
        this.animationFrame = window.requestAnimationFrame(this.animateRow(this.currentRow));
    };

    stopAnimating = () => {
        window.cancelAnimationFrame(this.animationFrame);
    };

    componentDidMount = () => {
        window.addEventListener('animationend', event => {
            if (event.animationName === 'fadeOut') event.target.style.animation = null;
        });
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `@keyframes fadeOut {
  0% {
    color: #c8b7b702;
  }
  20% {
    color: #c8b7b720;
  }
  100% {
    color: #c8b7b702;
  }
}`;
        document.body.appendChild(styleElement);
        this.startAnimating();
    };

    componnetWillUnmount = () => {
        this.stopAnimating();
    };
}
export default TuffStuff;
